export default function PrivacyPolicy() {
    return (
        <div style={{ maxWidth: '80%', margin: '0 auto' }}>
            <h1>Privacy Policy for Solvitech's Yaldo App</h1>
            <p><strong>Introduction</strong></p>
            <p>Yaldo is a digital platform designed to connect "belevers" with "belevenismakers" through unique experiences. This Privacy Policy outlines how we manage, protect, and use your data in compliance with privacy laws.</p>

            <h2>Collection of Personal and Sensitive Data</h2>
            <p>We collect a range of information to provide our services effectively. This includes:</p>
            <ul>
                <li><strong>Personal Identification Information:</strong> Names, addresses, email addresses, and payment details, provided during account creation and transaction processes.</li>
                <li><strong>Sensitive Data:</strong> We may collect demographic information, preferences, and data on user interactions with the app to tailor and enhance user experience.</li>
                <li><strong>User Activity:</strong> Data on how you use the app, your interactions with features, and preferences.</li>
            </ul>

            <h2>Data Usage and Sharing</h2>
            <p>Your data is used to:</p>
            <ul>
                <li>Enhance service functionality and user experience through personalization.</li>
                <li>Process transactions and manage user accounts efficiently.</li>
                <li>Communicate important account and service information.</li>
            </ul>
            <p>Data sharing is restricted to:</p>
            <ul>
                <li>Service providers who assist in our operations under confidentiality agreements, such as payment processors and marketing agencies.</li>
                <li>Legal authorities when required by law.</li>
            </ul>

            <h2>Data Security Measures</h2>
            <p>We implement several security measures to protect your personal information:</p>
            <ul>
                <li><strong>Encryption:</strong> Data transmitted between your device and our servers is encrypted.</li>
                <li><strong>Access Control:</strong> Only authorized personnel have access to sensitive data.</li>
                <li><strong>Anonymization and Pseudonymization:</strong> Where appropriate, we use anonymization and pseudonymization to protect your identity and privacy.</li>
                <li><strong>Regular Audits:</strong> Our systems and processes are regularly reviewed to ensure they meet our high security standards.</li>
            </ul>

            <h2>Privacy Risk Management</h2>
            <p>Following the LINDDUN framework, we have identified potential privacy threats and implemented mitigations to protect against:</p>
            <ul>
                <li>Unauthorized access to data.</li>
                <li>Unintended data disclosure.</li>
                <li>Insufficient user control over data.</li>
            </ul>

            <h2>User Rights</h2>
            <p>You have the right to access, correct, or request deletion of your personal data. Please contact us if you wish to exercise these rights.</p>

            <h2>Changes to Our Privacy Policy</h2>
            <p>This policy may be updated periodically to reflect changes in our practices. We will provide notice of any significant changes through our platform.</p>

            <h2>About This Policy</h2>
            <p>This Privacy Policy is provided by Solvitech, with the company registration number BE 0762.738.021. Solvitech is committed to protecting and respecting your privacy while providing a personalized and valuable experience.</p>

            <h2>Contact Information</h2>
            <p>For further information or to address your concerns, please contact us via email at <a href="mailto:team@yaldo.io">team@yaldo.io</a>.</p>
        </div>
    )
}
