import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';

import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import Belevenismakers from './routes/Belevenismakers/Belevenismakers';
import Belevers from './routes/Belevers/Belevers';
import Contact from './routes/Contact/Contact';
import ScrollToTop from './shared/components/ScrollToTop';
import PrivacyPolicy from './yaldo_privacy_policy';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <BrowserRouter>
    <ScrollToTop>
      <Routes>
      <Route path="/" element={<App />} />
      <Route path="/belevenismakers" element={<Belevenismakers />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/belevers" element={<Belevers />} />
      <Route path="/contact" element={<Contact />} />
      </Routes>
    </ScrollToTop>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
