import React from "react";
import { Link } from "react-router-dom";
import "./IconCard.scss";

interface Props{
    icon: string,
    text?: string,
    name?: string,
    rounded?: boolean,
    purple?: boolean,
    companyLink?: string,
    buttonPath?: string,
    team?: boolean,
    function?: string,
    borderRadius?: string,
}

export default function IconCard(props:Props){
    return(
        <article className="iconCard">
            {props.companyLink ? <a style={{margin: 'auto', marginBottom: '5%'}} rel="noreferrer" target={'_blank'} href={props.companyLink}><img src={props.icon} width='200px' alt="" {...props.borderRadius && props.team ? {style: {borderRadius: props.borderRadius}} : null} {...props.rounded ? {style: {borderRadius: '50%'}} : null}/></a> : <img src={props.icon} width='200px' alt="" {...props.borderRadius && props.team ? {style: {borderRadius: props.borderRadius}} : null} {...props.rounded ? {style: {borderRadius: '50%'}} : null}/>}
            {props.team ? <><h3 style={{marginBottom: 0, color: '#19005E'}}>{props.name}</h3><h6>{props.function}</h6></> : null}
            {props.team && props.text ? <span {...props.text && props.team ? {style:{paddingBottom: '20%', paddingTop: 40, fontSize: "0.9em" , color: "rgb(25, 0, 94)"}} : null}>{props.text}</span> : null}
            {props.text && !props.team ? <span {...props.text && props.rounded ? {style:{marginBottom:0}} : null}>{props.text}</span> : null}
            {props.name && !props.team ? <em {...!props.purple && !props.team ? {style: {color: '#8B79B6'}} : {style: {color: 'white'}}}>~ {props.name}</em> : null}

            {props.buttonPath ? <Link className="button" to={props.buttonPath} {...props.purple ? {style: {backgroundColor: "white", color: "#19005E" }} : {style:{backgroundColor: "#19005E", color: "white"}}}>Bekijk het hier!</Link> : null}
        </article>
    )
}