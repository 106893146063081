import CSS from 'csstype';
import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import logoTextWhite from  '../../../assets/yaldo_icon-text_white.svg';
import "./Hamburger.scss";

interface Props {
    purple?: boolean;
}

export default function Hamburger(purple: Props) {

    const location = useLocation();
    const [hamburgerOpen, setHamburgerOpen] = useState<boolean>(false);
    const [colorChange, setColorChange] = useState<boolean>(false);

    const toggleHamburger = () => {
        setHamburgerOpen(!hamburgerOpen);
    }

    const changeNavbarColor = () => {

        if(window.scrollY >= 50){
            setColorChange(true);
        } else {
            setColorChange(false);
        }
    }

    console.log(purple)


    useEffect(()=> {
        window.addEventListener('scroll', changeNavbarColor);
        return () => {
            window.removeEventListener('scroll',changeNavbarColor)
        }
    },[])
    

    const hamburger : CSS.Properties = {
        width: "2rem",
        height: "2rem",
        display: "flex",
        justifyContent: "space-around",
        flexFlow: "nowrap column",
        float: "right",
    }

    const burger1 : CSS.Properties = {
        transform: 'rotate(0)',
    }

    const burger1Ham : CSS.Properties = {
        transform: 'rotate(45deg)',
    }

    const burger2 : CSS.Properties = {
        transform: 'translate(0)',
        opacity: "1",
    }

    const burger2Ham : CSS.Properties = {
        transform: 'translateX(100%)',
        opacity: "0",
    }

    const burger3 : CSS.Properties = {
        transform: 'rotate(0)',
    }

    const burger3Ham : CSS.Properties = {
        transform: 'rotate(-45deg)',
    }


    const ulHamburgerOpen : CSS.Properties = {
        display: 'inline',
        backgroundColor: '#39227A',
        height: '150vh',
        width: '100%',
        top: '85px',
        position: 'absolute',
        margin: '0',
        float: 'right',
    }

    const notOpen : CSS.Properties = {
        display: 'none',
        width: 0,
    }

    const checkPurple = () => {
        if(purple.purple){
            console.log(purple.purple)
            return <div className="hamburgerWrapper" onClick={toggleHamburger} style={colorChange ? {backgroundColor: '#39227A', transition: "all 0.5s linear"} : {backgroundColor: '#19005E'}}>
                <a className='hamburgerLogo' href="/"><img src={logoTextWhite} width="150px" alt="" /></a>
                <div className='hamburger' style={hamburger}>
                    <div className="burger" style={hamburgerOpen ? burger1Ham : burger1}></div>
                    <div className="burger" style={hamburgerOpen ? burger2Ham : burger2}></div>
                    <div className="burger" style={hamburgerOpen ? burger3Ham : burger3}></div>
                </div>
                <ul style={hamburgerOpen ? ulHamburgerOpen : notOpen}>
                    <li><Link {...location.pathname === '/' ? {style: {color: '#CDC2E8'}}: null} to="/">home</Link></li>
                    <li><Link {...location.pathname === '/belevenismakers' ? {style: {color: '#CDC2E8'}}: null} to="/belevenismakers">belevenismakers</Link></li>
                    <li><Link {...location.pathname === '/belevers' ? {style: {color: '#CDC2E8'}}: null} to="/belevers">belevers</Link></li>
                    <li><Link {...location.pathname === '/contact' ? {style: {color: '#CDC2E8'}}: null} to="/contact">contact</Link></li>
                </ul>
            </div>
        } if(!purple.purple){
            return <div className="hamburgerWrapper" onClick={toggleHamburger} style={colorChange ? {backgroundColor: '#39227A', transition: "all 0.5s linear"} : {backgroundColor: 'initial'}}>
            <a className='hamburgerLogo' href="/"><img src={logoTextWhite} width="150px" alt="" /></a>
            <div className='hamburger' style={hamburger}>
                <div className="burger" style={hamburgerOpen ? burger1Ham : burger1}></div>
                <div className="burger" style={hamburgerOpen ? burger2Ham : burger2}></div>
                <div className="burger" style={hamburgerOpen ? burger3Ham : burger3}></div>
            </div>
            <ul style={hamburgerOpen ? ulHamburgerOpen : notOpen}>
                <li><Link {...location.pathname === '/' ? {style: {color: '#CDC2E8'}}: null} to="/">home</Link></li>
                <li><Link {...location.pathname === '/belevenismakers' ? {style: {color: '#CDC2E8'}}: null} to="/belevenismakers">belevenismakers</Link></li>
                <li><Link {...location.pathname === '/belevers' ? {style: {color: '#CDC2E8'}}: null} to="/belevers">belevers</Link></li>
                <li><Link {...location.pathname === '/contact' ? {style: {color: '#CDC2E8'}}: null} to="/contact">contact</Link></li>
            </ul>
        </div>
        }
    }

    return(
        <>
        {checkPurple()}
        </>
        
    )
}