import React, { useState } from "react";
import Footer from "../../shared/core/Footer/Footer";
import Nav from "../../shared/core/Nav/Nav";
import Section from "../../shared/core/Section/Section";
import "./Contact.scss";

import emailjs from "emailjs-com";

export default function Contact(){

    const [name, setName] = useState<string>("");
    const [firstName, setFirstName] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [message, setMessage] = useState<string>("");

    const submit = () => {
        if(name && firstName && email && message){
            const serviceId = 'service_mzry52u';
            const templateId = 'template_4qkoo7i';
            const userId = "Wh2X3Hnzry_EX-uGn"
            const templateParams = {
                name,
                firstName,
                email,
                message
            };

            emailjs.send(serviceId, templateId, templateParams, userId)
            .then(response => {
                console.log(response);
            })
            .then(error => {
                console.log(error);
            }
            );

            setName('');
            setFirstName('');
            setEmail('');
            setMessage('');
            showSpan("Bedankt voor uw bericht, we antwoorden je zo snel mogelijk.");
        }else{
            showSpan("Gelieve alle velden in te vullen.");
        }
    }

    const showSpan = (message: string)=>{
        const id = document.getElementById("statusMessage");
        if(id){

            if(message === "Thank you for your message, we will be in touch in no time!"){
                id.setAttribute("class", "confimation");

                id.innerHTML = message;
            } else {
                id.setAttribute("class", "failed")

                id.innerHTML = message;
            }
            // id.innerHTML = message
        }
    }
    return(
        <>
        <Nav purple></Nav>
        <div style={{paddingTop: '100px'}}>
            <Section sectionTitle={'Een vraagje?'} sectionText={'Een vraag? Wil je graag een belevenismaker worden op yaldo? Kijk je er al naar uit om belevenissen te boeken? Stuur ons een berichtje via dit formulier.'}>
                <div className="contact">
                    <div className="names">
                        <div className="lastName">
                            <label htmlFor="lastName">Naam*</label>
                            <input required type="text" name="lastName" placeholder="Doe" value={name} onChange={e => setName(e.target.value)}/>
                        </div>
                        <div className="firstName">
                            <label htmlFor="firstName">Voornaam*</label>
                            <input required className='firstName' type="text" name="firstName" placeholder="John" value={firstName} onChange={e => setFirstName(e.target.value)}/>
                        </div>
                    </div>
                    <label htmlFor="email">E-mailadres*</label>
                    <input required type="email" name="email" placeholder="john.doe@mail.com" value={email} onChange={e => setEmail(e.target.value)}/>
                    <label htmlFor="message">Boodschap*</label>
                    <input required className="message" placeholder="Schrijf hier jouw boodschap/vraag." type='text' value={message} onChange={e => setMessage(e.target.value)}></input>
                    <input type="submit" value={'Verstuur'} placeholder="" onClick={submit}/>
                    <div id="statusMessage"></div>
                </div>
            </Section>
        </div>
        <Footer></Footer>
        </>
    )
}