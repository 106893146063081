import React from "react";
import Header from "../../shared/core/Header/Header";
import Nav from "../../shared/core/Nav/Nav";
import './Belevers.scss'

import yaldoBelever from "../../assets/yaldo-hallo-belever.png";
import Section from "../../shared/core/Section/Section";
import IconCard from "../../shared/components/IconCard/IconCard";

// images
import WalkingInFrame from "../../assets/WalkingInFrame.png";
import GroupOfFriends from "../../assets/GroupOfFriends.png";
import OutOfPhone from "../../assets/OutOfPhone.png";

// what they say images
import Belever_Billal from '../../assets/Belever_BillalKhorrich.png';
import Person_IndyDeMulder from '../../assets/Person_IndyDeMulder.png';
import Footer from "../../shared/core/Footer/Footer";

export default function Belevers(){
    return(
        <div className="App">
            <Nav></Nav>
            <Header specialHeader title={"Hey, belever!"} text={"Beleef samen meer mooie momenten"} icon={yaldoBelever}></Header>
            <article id="nextSection" className='yaldoExampleMore gray yaldoOnline'>
                <h2>yaldo gaat online vanaf eind 2023!</h2>
           
            </article>
            <Section title={'yaldo voor belevers'} text={'Een yaldo is niet zomaar een activiteit, het is een moment dat je samen beleeft, dat zorgt voor unieke herinneringen, straffe verhalen en nieuwe vriendschappen.'}>
                <IconCard icon={WalkingInFrame} text={'(Be)leef je leven op een buitengewone manier.'}></IconCard>
                <IconCard icon={GroupOfFriends} text={'Breng je vrienden samen en creëer de belevenissen die het waard zijn te koesteren.'}></IconCard>
                <IconCard icon={OutOfPhone} text={'Neem je leven en vriendschappen offline, geniet van de échte wereld.'}></IconCard>
            </Section>
            <div className="steps">
                <div className="wrapper">
                    <aside className="number">1</aside>
                    <div className="text">
                        <h1>Zoek een boeiende belevenis</h1>
                        <p>Een gaatje in jouw agenda en je wil wel eens iets anders? Open de yaldo-app en schiet in actie!<br /> <br /> Speur door het aanbod van belevenissen en vind wat bij jou past, of juist niet. Breek uit je comfortzone!</p>
                    </div>
                </div>
                <div className="wrapper">
                    <aside className="number">2</aside>
                    <div className="text">
                        <h1>Boek en deel jouw belevenis</h1>
                        <p>Nodig gemakkelijk al jouw vrienden uit om deel te nemen aan jouw yaldo, via de app. Volg jouw uitnodigingen gemakkelijk op.<br /> <br /> Ben je klaar om ook nieuwe mensen te ontmoeten op jouw yaldo? Ook dat kan door je yaldo open te stellen voor mensen met dezelfde passies.</p>
                    </div>
                </div>
                <div className="wrapper">
                    <aside className="number">3</aside>
                    <div className="text">
                        <h1>Geniet van jouw yaldo!</h1>
                        <p>Het moment is aangebroken, iedereen present. Je yaldo gaat van start. Laat je fascineren door ervaren belevenismakers en geniet van het samenzijn, de vriendschap en de sfeer.<br /> <br /> Deel jouw ervaringen, hoogtepunten en gedachten met je vrienden en blik af en toe eens terug op dit unieke moment.</p>
                    </div>
                </div>
            </div>

            <Section backgroundIcon sectionTitle='Wat de belevers zeggen'>
                <IconCard rounded icon={Belever_Billal} name={'Billal Khorrich'} text={'De experiences van morgen staan ons op te wachten... en ik kijk er naar uit!'}></IconCard>
                <IconCard rounded icon={Person_IndyDeMulder} name={'Indy De Mulder'} text={'yaldo is één van de eerste beleveniszoekers die een vaste plaats op mijn gsm verdient.'}></IconCard>
                {/* <IconCard rounded icon={andyTeam} name={'Andy Callens'} text={'Klankbord en opschalende kracht binnen het project. Is als full-stack developer de technische lead van het team en meester van de Yaldo-broncode.'}></IconCard> */}
            </Section>
            <Footer></Footer>
        </div>
    )
}