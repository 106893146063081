import React from 'react';
import './App.scss';
import Header from './shared/core/Header/Header';
import Nav from './shared/core/Nav/Nav';
import YaldoHomeIcon from './assets/yaldo_home_icon.png';
import Section from './shared/core/Section/Section';
import Footer from './shared/core/Footer/Footer';
import IconCard from './shared/components/IconCard/IconCard';
import Article from './shared/core/Section/Article/Article';
import Quote from './shared/components/Quote/Quote';

// images
import belevenisMakers from './assets/belevenismakers.png';
import belever from './assets/belever.png';

// cards examples
import CardCluedo from "./assets/Card-CluedoDiner.png";
import CradVr from "./assets/Card_VRHub.png";
import CardGarden from "./assets/Card_GardenMovieNight.png";

// what they say images
import andyTeam from './assets/yaldoteam_andy.jpg';
import jordyTeam from './assets/yaldoteam_jordy.jpg';
import axelTeam from './assets/yaldoteam_axel.jpg';
import jariTeam from './assets/yaldoteam_jari.jpg'
import Logo_Meta3D from './assets/Logo_Meta3D.png';
import Logo_JamesWhisky from  './assets/Logo_James&Whisky.png';
import Belever_Billal from './assets/Belever_BillalKhorrich.png';
import Person_IndyDeMulder from './assets/Person_IndyDeMulder.png';
import Who from './assets/who.webp';


// sponsors
// import maisonPost from './assets/MaisenDeLaPoste_Logo_White.svg';

function App() {
  return (
    <div className="App">
      <Nav></Nav>


      <Header title={'Alle belevenissen, één platform'} text={'Je weet niet wat te doen dit weekend... Je wilt iets beleven dat je nog nooit hebt ervaren. Boek een yaldo!'} icon={YaldoHomeIcon} inputField></Header>
      
      <article className='yaldoExampleMore gray yaldoOnline'>
            <h2 id='nextSection'>yaldo launched binnenkort!</h2>

           
      </article>
      <section className='section'>
        <h1 style={{margin: 'auto', textAlign: 'center', color: '#19005E'}}>yaldo-watte???</h1>
        <p style={{margin: 'auto', textAlign: 'center', color: "#8B79B6", paddingTop: '5%'}}>
        Yaldo is hét platform dat mensen samenbrengt. <b>Ontdek boeiende belevenissen,</b> nodig je vrienden uit en ervaar samen de momenten die je nog lang zal koesteren. <br /> <br /> Zeg nooit zomaar "activiteit" tegen een yaldo. Een yaldo is veel meer dan dat. Het is een beleving, een ontdekking, <b>een ervaring die je deelt met anderen. </b><br /> <br /> Van relax tot high level adrenaline, van recht door zee tot mysterieus… <br /><b>Ontdek op yaldo het ruime aanbod aan ervaringen die onze belevenismakers met jou willen delen.</b>
        </p>
      </section>
      {/* <Section title={'yaldo-watte???'} text={'Yaldo is hét platform dat mensen samenbrengt. Ontdek boeiende belevenissen, nodig je vrienden uit en ervaar samen de momenten die je nog lang zal koesteren. Zeg nooit zomaar "activiteit" tegen een yaldo. Een yaldo is veel meer dan dat. Het is een beleving, een ontdekking, een ervaring die je deelt met anderen. Van relax tot high level adrenaline, van recht door zee tot mysterieus, …Ontdek op yaldo het ruime aanbod aan ervaringen die onze belevenismakers met jou willen delen.'} >
      </Section> */}



      <Section>
        <Article purple title={'Yaldo voor belevenismakers'} text={'Verspreid je passie en inspireer! Creëer en deel belevenissen waar mensen samen van genieten.'}>
          <IconCard purple icon={belevenisMakers} buttonPath={'/belevenismakers'}></IconCard>
        </Article>
        <Article title={'Yaldo voor belevers'} text={'Een yaldo is een moment dat je samen beleeft, dat zorgt voor unieke herinneringen en nieuwe vriendschappen.'}>
          <IconCard icon={belever} buttonPath={'/belevers'}></IconCard>
        </Article>
      </Section>

      <Quote purple></Quote>

      <Section sectionTitle='Hoe kan een yaldo eruitzien?*' sectionText="*Dit zijn nog niet echt te boeken yaldo's, maar wie weet...">
          <div className="yaldoExamples">
            <div className="example">
              <aside><img src={CardCluedo} alt="een yaldo event omtrent Cluedo" /></aside>
              <div className="yaldoExampleText">
                <h5>Cluedo diner party</h5>
                <p>Alle gekheid op een stokje, en het is nog lekker ook! Geniet van een culinaire sensatie terwijl je, samen met jouw vrienden, een waanzinnig moordverhaal oplost. Deze avond is niet voor hen met een klein hartje en een kleine buik.</p>              
              </div>
            </div>

            <div className="example">
              <aside><img src={CradVr} alt="een yaldo event omtrent VR" /></aside>
              <div className="yaldoExampleText">
                <h5>VR Hub</h5>
                <p>Snap back to virtual reality. Verlies jezelf in de virtuele wereld, waar alles mogelijk is. Zelfs het onmogelijke... Houd buitengewone wedstrijden en bewijs aan jouw vrienden voor eens en altijd, dat jij de ultimate gamer bent!</p>                            
              </div>
            </div>

            <div className="example">
              <aside><img src={CardGarden} alt="een yaldo event omtrent Graden movie night" /></aside>
              <div className="yaldoExampleText">
                <h5>Garden movie night</h5>
                <p>Weg met die prullerige laptop die steeds weer tijdens de meest spannende scene uitvalt. Breng de enige echte cinemaervaring naar jouw tuin en kijk die Harry Potter marathon die je al maanden denkt te zien, samen met jouw vrienden. Popcorn inbegrepen!</p>              
              </div>
            </div>
          </div>
      </Section>
      <Section>
          <article className='yaldoExampleMore'>
            <h2>En véél meer</h2>
            <p>Bedenk de uniekste, coolste, gekste belevenissen ooit! Vind je ze niet op yaldo? Dan kan je gewoon zelf <a href="/belevenismakers">een belevenismaker</a> worden.</p>
          </article>
      </Section>

      <Section purple backgroundIcon sectionTitle='Wat de belevenismakers zeggen'>
        <IconCard purple rounded icon={Logo_Meta3D} companyLink={'https://meta3d.be/'} name={'Meta3D, VR Experiences'} text={'Yaldo geeft ons bedrijf meer zichtbaarheid en bekendheid zodat de klanten kunnen ontdekken dat virtual reality meer is dan alleen maar gamen.'}></IconCard>
        <IconCard purple rounded icon={Logo_JamesWhisky} name={'James & Whisky, Whisky Tastings'} text={'Ik heb het volste vertrouwen in dit project. Je zal James & Whisky als één van de eerste aanbieders tegenkomen op yaldo.'}></IconCard>
        {/* <IconCard purple rounded icon={andyTeam} name={'Andy Callens'} text={'Klankbord en opschalende kracht binnen het project. Is als full-stack developer de technische lead van het team en meester van de Yaldo-broncode.'}></IconCard> */}
      </Section>

      <Section backgroundIcon sectionTitle='Wat de belevers zeggen'>
        <IconCard rounded icon={Belever_Billal} name={'Billal Khorrich'} text={'De experiences van morgen staan ons op te wachten... en ik kijk er naar uit!'}></IconCard>
        <IconCard rounded icon={Person_IndyDeMulder} name={'Indy De Mulder'} text={'yaldo is één van de eerste beleveniszoekers die een vaste plaats op mijn gsm verdient.'}></IconCard>
        {/* <IconCard rounded icon={andyTeam} name={'Andy Callens'} text={'Klankbord en opschalende kracht binnen het project. Is als full-stack developer de technische lead van het team en meester van de Yaldo-broncode.'}></IconCard> */}
      </Section>

      <Section white sectionTitle={'Het team'} sectionText={'Sinds een paar jaar terug hebben we de missie om mensen weer dichterbij elkaar te brengen in de échte wereld. Nu maken we dit realiteit.'}>
        {/* <IconCard team borderRadius={'65% 5% 5% 5%'}icon={jordyTeam} name={'Jordy Callens'} function={'Projectmanager  |  Co-founder'} text={'Organisatorische, opbouwende en stuwende kracht van het team. Transformeert ideeën naar grafische blauwdrukken, met oog voor detail en gebruiksvriendelijkheid.'}></IconCard> */}
        <IconCard team borderRadius={'5% 5% 5% 65%'}icon={axelTeam} name={'Axel Lemmmens'} function={'Senior Developer  |  Advisor'} text={'Klankbord en opschalende adviseur binnen het project. Is als ervaren full-stack developer mede bewaker van de yaldo-broncode'}></IconCard>
        <IconCard team borderRadius={'5% 65% 5% 5%'}icon={jariTeam} name={'Jari Miers'} function={'Founder'} text={'Doelgericht en initiatiefnemer die de puntjes op de i zet. Is mede bewaker van de yaldo-broncode als full-stack developer.'}></IconCard>
        <IconCard team borderRadius={'5% 65% 5% 5%'}icon={Who} name={'Ben jij onze nieuwe Creative Developer?'} function={'Full-Stack Developer'} text={'Als je van coderen houdt en een passie hebt voor het ontwikkelen van indrukwekkende digitale ervaringen, zoeken we jou. Help ons de yaldo-broncode te bewaken en breng jouw creatieve visie tot leven in ons team.'}></IconCard>
        <IconCard team borderRadius={'5% 65% 5% 5%'}icon={Who} name={'Sales Talent met IT-Affiniteit?'} function={'Sales'} text={'Ben je een natuurlijke verkoper met een goede dosis IT-kennis? Help ons de brug te slaan tussen technologie en klantbehoeften, en word het gezicht van innovatie voor onze klanten.'}></IconCard>
        {/* <IconCard team borderRadius={'5% 5% 65% 5%'}icon={andyTeam} name={'Andy Callens'} function={'Creative Consultant  |  Co-founder'} text={'Bezieler en visionaire kracht van yaldo. Ziet mogelijkheden en zet ze om in concrete en effectieve toepassingen en strategieën.'}></IconCard> */}
      </Section>

      {/* <Section purple sectionTitle={'Met de steun van'}>
        <Link to={'https://tour-taxis.com/nl/meet-and-organise/al-onze-venues/maison-de-la-poste/?fbclid=IwAR1n7H17JpLixcmbejtdscm3tdyJqPjt02NcNRyt2Rjl-zqJe_EiUmBS8RI'}><IconCard icon={maisonPost}></IconCard></Link>
        <a target={'_blank'} className="sponsor" href={'https://tour-taxis.com/nl/meet-and-organise/al-onze-venues/maison-de-la-poste/?fbclid=IwAR1n7H17JpLixcmbejtdscm3tdyJqPjt02NcNRyt2Rjl-zqJe_EiUmBS8RI'} rel="noreferrer"><IconCard icon={maisonPost}></IconCard></a>
      </Section> */}

      <Footer></Footer>
    </div>
  );
}

export default App;
