import React from "react";
import './Footer.scss';
import AlgemeneVoorwaarden from '../../../assets/yaldoAV.pdf';
import { Link } from "react-router-dom";
export default function Footer(){
    const date = new Date().getFullYear();
    return(
        <footer>
            <p><a href='mailto:team@yaldo.io'>team@yaldo.io</a></p>
            <div className="document-links">
                <a href={AlgemeneVoorwaarden} target="_blank" rel="noopener noreferrer">Algemene Voorwaarden</a>
                <Link to="/privacy-policy">Privacy Policy</Link>
            </div>
            <p className="last">© {date} - yaldo</p>
        </footer>
    )
}
