import React from "react";
import "./Article.scss";

interface Props{
    purple?: boolean,
    title: string,
    text: string
    children?: any
}


export default function Article(props: Props){

    const parseTitle = (title : string) => {
        let splitString = title.split(" ");

        const lastItem = splitString[splitString.length - 1];

        splitString.pop()

        return <h2>{splitString.join(" ")} <em style={{fontFamily: "RobotoBlackItalic"}}>{lastItem}</em></h2>


    }

    return(
        <article className="articleArticle" {...props.purple ? {style:{backgroundColor: "#19005E", color: 'white'}} : {style:{backgroundColor: "#F9F9F9", color:"#19005E"}}}>
            <div>
                {parseTitle(props.title)}
                <p>{props.text}</p>
            </div>
            {props.children}
        </article>
    )
}