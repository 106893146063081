import { Link, useLocation } from "react-router-dom";
import "./Nav.scss";
import logoTextWhite from  '../../../assets/yaldo_icon-text_white.svg';
import Hamburger from "./Hamburger";

interface Props{
    purple?: boolean
}


export default function Nav(props : Props) {

    const location = useLocation();
    return(
        <nav {...props.purple ? {style:{backgroundColor: "#19005E"}} : null}>
            <ul>
                <li><a href="/"><img src={logoTextWhite} width="150px" alt="" /></a></li>
                <li><Link {...location.pathname === '/belevenismakers' ? {style: {color: '#CDC2E8'}}: null} to="/belevenismakers">belevenismakers</Link></li>
                <li><Link {...location.pathname === '/belevers' ? {style: {color: '#CDC2E8'}}: null} to="/belevers">belevers</Link></li>
                <li><Link {...location.pathname === '/contact' ? {style: {color: '#CDC2E8'}}: null} to="/contact">contact</Link></li>
            </ul>
            
            {props.purple ? <Hamburger purple></Hamburger> : <Hamburger></Hamburger>}
         </nav>
    )
}