import React, { useState } from "react";
import AnchorLink from 'react-anchor-link-smooth-scroll';
import './Header.scss';
import yaldoScroll from "../../../assets/yaldoScroll.png"
import MailchimpSubscribe from "react-mailchimp-subscribe";

interface Props{
    title: string,
    text: string,
    icon: string,
    specialHeader?: boolean;
    inputField?: boolean
}

export default function Header(props : Props){

    const [email, setEmail] = useState<string>("");


    let firstPiece
    let lastPiece

    if(props.specialHeader) {
      if(props.title.length < 21) {
        const splitOnSpace = props.title.split(" ");

        firstPiece = splitOnSpace[splitOnSpace.length - 2]

        lastPiece = splitOnSpace.pop();
      }
    }

    return(
    <header>
        <div className="yaldoBackground"></div>
        <div className="top">
            <div className="text">
                {props.specialHeader ? <><h1 className="specialHeaderTitle" style={{color: '#8B79B6'}}>{firstPiece} <span style={{color: 'white'}}>{lastPiece}</span></h1><p style={{ fontSize: '1.5em', fontFamily: 'RobotoMedium', width: '100%' }}>{props.text}</p></> : <><h1>{props.title}</h1><p>{props.text}</p></>}
                {props.inputField ?
                    <div className="inputField">
                        <MailchimpSubscribe 
                         url="https://yaldo.us1.list-manage.com/subscribe/post?u=5c28a5fdaaa7e1150a12376ff&amp;id=0b7df92ec0"
                         render={({ subscribe, status, message }) => (
                         <>
                         <input type="email" placeholder="Geef je mailadres en ontvang updates over yaldo" value={email} onChange={(e) => setEmail(e.target.value)} required />
                         <input type="submit" value="OK" onClick={() => subscribe({ EMAIL: email })}/>
                         {status === "sending" && (
                        <div style={{color: "white", paddingTop: 20}}>Verzenden...</div>
                      )}
                      {status === "error" && (
                        <div style={{color: "red", paddingTop: 20}}>
                          Er is iets mis gelopen...
                        </div>
                      )}
                      {status === "success" && (
                        <div style={{color: 'green', paddingTop: 20}}>
                          Geregistreerd, bedankt voor de aanmelding!
                        </div>
                      )}
                         </>
                         )}
                        />
                    </div>
                    : null}
            </div>
            <img src={props.icon} alt="app example" />
        </div>
        <AnchorLink href="#nextSection"><img src={yaldoScroll} id="scrollButton" alt="" width='20px' /></AnchorLink>
    </header>
    )
}