import React from "react";
import "./Section.scss";


interface Props{
    backgroundIcon?: boolean,
    purple?: boolean,
    white?: boolean
    sectionTitle?: string,
    sectionText?: string,
    title?: string,
    text?: string,
    children?: any,
}

export default function Section(props: Props) {

    let lastItemTitle = '';

    const parseTitle = (title: string | undefined) => {
        if(title){
            let splitTitle= title.split(" ")

            if(splitTitle[splitTitle.length - 1].valueOf() === "belevenismakers" || splitTitle[splitTitle.length - 1].valueOf() ===  "belevers"){
                lastItemTitle = splitTitle[splitTitle.length - 1];

                splitTitle.pop()
    
                return <h1 style={{margin: 'auto', textAlign: 'center'}}>{splitTitle.join(" ")} <b><em>{lastItemTitle}</em></b></h1>
            } else {
                return <h1 style={{margin: 'auto', textAlign: 'center'}}>{splitTitle.join(" ")}</h1>
            }
        }

    }

    parseTitle(props.title)

    return(
    <>
    <section className="section" {...props.purple ? {style:{backgroundColor: "#19005E", color: 'white'}} : {style:{backgroundColor: "#F9F9F9", color:"#19005E"}} } {...props.white ? {style:{backgroundColor: "white", color:"#19005E"}} : null}>
        {props.backgroundIcon && props.purple? <div className="PurpleYaldoBackground"></div> : null}
        {props.backgroundIcon && !props.purple ? <div className="whiteYaldoBackground"></div> : null} 
        {props.sectionTitle ?<h2 style={{margin: 'auto', textAlign: 'center'}}>{props.sectionTitle}</h2> : null}
        {props.sectionText ? <p style={{margin: 'auto', textAlign: 'center', color: "#8B79B6"}}>{props.sectionText}</p> : null}
        {props.title || props.text ? 
        <article>
            {parseTitle(props.title)}
            <p className="specialParagraph">{props.text}</p>
        </article> : null}
            
        {props.children ? <div className="children">
            {props.children}
        </div> : null}

    </section>
    </>
    )
}