import React from "react";
import Header from "../../shared/core/Header/Header";
import Nav from "../../shared/core/Nav/Nav";
import Section from "../../shared/core/Section/Section";
import './Belevenismakers.scss'
import IconCard from "../../shared/components/IconCard/IconCard";

import yaldoBelevenismaker from "../../assets/yaldo-hallo-belevenismaker.png";

// images
import belevenisMakers from '../../assets/belevenismakers.png';
import beheerschermen  from '../../assets/beheerschermen.png';


// image belevenisMakers tools description
import yaldoDesktop from "../../assets/yaldo-desktop.png";
import WalkingInFrame from "../../assets/WalkingInFrame.png";

// what they say images
import Footer from "../../shared/core/Footer/Footer";
import Logo_Meta3D from '../../assets/Logo_Meta3D.png';
import Logo_JamesWhisky from  '../../assets/Logo_James&Whisky.png';

export default function Belevenismakers(){
    return(
        <><Nav></Nav>
        <Header specialHeader title={"Hey, belevenismaker!"} text={"Bereik een breder publiek met jouw passie"} icon={yaldoBelevenismaker}></Header>
        <article id="nextSection" className='yaldoExampleMore gray yaldoOnline'>
            <h2>yaldo gaat online vanaf eind 2023!</h2>
           
        </article>
        <Section title={'yaldo voor belevenismakers'} text={'Verspreid je passie en inspireer! Creëer en deel belevenissen waar mensen samen van genieten.'}>
          <IconCard icon={belevenisMakers} text={'Creëer jouw unieke belevenissen en plaats ze op yaldo.'}></IconCard>
          <IconCard icon={beheerschermen} text={"Verspreid en beheer jouw yaldo's gemakkelijk online!"}></IconCard>
          <IconCard icon={WalkingInFrame} text={'Ontvang boekingen en breng je belevenis tot leven!'}></IconCard>
        </Section>
        <Section purple>
        <div className='belevenisDescription'>
          <ol>
            <li>Creëer en deel
              <ul>
                <li>Maak snel en eenvoudig een yaldo-belevenis aan in jouw persoonlijk beheercentrum. Stel gemakkelijk alle parameters in en deel jouw yaldo met de wereld.</li>
              </ul>
            </li>
            <li>Ontvang boekingen
              <ul>
                <li>Ontvang en bevestig aanvragen van geïnteresseerde belevers.
                  Beheer al je boekingen, communicatie en betalingen via je beheerscherm. 
                  Plan ze in in jouw eigen yaldo-agenda.</li>
              </ul>
            </li>
            <li>Breng de ervaring
              <ul>
                <li>Neem je klanten mee in jouw passie, laat hen unieke momenten beleven die ze kunnen delen met hun vrienden.
Verhoog eventueel jouw omzet met de verkoop van gerelateerde artikelen/producten.</li>
              </ul>
            </li>
          </ol>
          <img src={yaldoDesktop} width='35%' alt="" />
        </div>

      </Section>
      <Section white sectionTitle="De kracht van yaldo">
        <div className="yaldoPower">
          <h3>Leef je passie</h3>
          <p>Heb jij een product of dienst waar al jouw passie inzit? Of wist je tot nu toe nog niet hoe dat te doen? Je passie delen met de mensheid is nu gemakkelijker dan ooit. Bouw er een belevenis rond en verspreid deze via yaldo.</p>

          <h3>Bereik meer klanten</h3>
          <p>Op yaldo bereik je de mensen die op zoek naar leuke, originele, leerrijke, avontuurlijke en boeiende belevenissen. Neem hen mee in jouw passie en bied hen een belevenis die zij daarna nog lang zullen doorvertellen. Dat leidt dan weer tot nieuwe klanten.</p>

          <h3>Boost je verkoop</h3>
          <p>Door je belevenissen zo uniek en memorabel mogelijk te maken, overtuig je je (potentiële) klanten om volgende yaldo's te boeken of om jouw producten te kopen uit je eigen yaldo-store.</p>
        </div>
      </Section>

      <article className='yaldoExampleMore gray'>
            <h2>Word een belevenismaker!</h2>
            <p>Wil jij graag belevenissen aanbieden op yaldo en zo jouw passie delen met iedereen? Stuur ons gerust <a href="/contact">een mailtje.</a></p>
          </article>

      <Section purple backgroundIcon sectionTitle='Wat de belevenismakers zeggen'>
        <IconCard purple rounded icon={Logo_Meta3D} name={'Meta3D, VR Experiences'} text={'Yaldo geeft ons bedrijf meer zichtbaarheid en bekendheid zodat de klanten kunnen ontdekken dat virtual reality meer is dan alleen maar gamen.'}></IconCard>
        <IconCard purple rounded icon={Logo_JamesWhisky} name={'James & Whisky, Whisky Tastings'} text={'Ik heb het volste vertrouwen in dit project. Je zal James & Whisky als één van de eerste aanbieders tegenkomen op yaldo.'}></IconCard>
        {/* <IconCard purple rounded icon={andyTeam} name={'Andy Callens'} text={'Klankbord en opschalende kracht binnen het project. Is als full-stack developer de technische lead van het team en meester van de Yaldo-broncode.'}></IconCard> */}
      </Section>
      <Footer></Footer>
        </>
    )
}