import React from "react";
import "./Quote.scss";


interface Props{
    purple?: boolean
}

export default function Quote(props : Props){
    return(
        <section className="quote" {...props.purple ? {style:{backgroundColor: "#19005E", color: 'white'}} : {style:{backgroundColor: "#F9F9F9", color:"#19005E"}} }>
            <h4>dedigitalisering <sub>(de; v)</sub></h4>
            <ol>
                <li><b>de dedigitalisering: </b> <em>Tijd die we nu online spenderen omzetten in tijd die we in de échte wereld samen, met elkaar doorbrengen...</em>
                </li>
            </ol>
        </section>
    )
}